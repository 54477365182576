/** @file Controla las acciones de elementos relacionados a tareas pendientes */
import onmount, { $ } from 'onmount';

const mounted = { 'mobile': false, 'desktop': false };
const openPendingTaskSidebar = async function (device) {
  if (mounted[device]) return;

  mounted[device] = true;

  const $toogleButton = (device === 'desktop') ? $('#toggle-aside-reminder-navbar-loaded') : $('#tareas-tab');
  const locationSearch = new URLSearchParams(window.location.search);
  const deliveryQuery = locationSearch.get('delivery');
  const refererQuery = locationSearch.get('referer');

  if (!deliveryQuery || !refererQuery) return;

  if (deliveryQuery === 'pending_task_reminder' && refererQuery === 'mail') {
    $toogleButton.click();
  }
};

const isMobile = function () {
  return window.matchMedia('(max-width: 960px)').matches;
};

// Se encarga de actualizar el widget de Tareas Pendientes del portal cuando ocurre un cambio
// desde el side bar de Tareas Pendientes.
onmount('#pending-aside', function () {

  // Se elimina el ic-deps del widget de TP para que no se actualice 2 veces al archivar una TP
  // desde el sidebar
  var pendingWidget =  $('#pending-task-widget');
  const widgetPath = pendingWidget.attr('ic-deps');
  pendingWidget.attr('ic-deps', 'ignore');

  var sidebar = document.querySelector('.aside-base');
  var sidebarContent = document.querySelector('#pending-aside');

  /* eslint-disable no-undef */
  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      // Detecta si hubo un cambio en la lista de Tareas Pendientes
      if ($(mutation.target).attr('id') === 'pending-aside' && mutation.addedNodes.length > 0) {
        Intercooler.triggerRequest($('#pending-task-widget'));
      }

      // Una vez que se cierra el sidebar, se le agrega nuevamente el ic-deps al widget
      if ($(mutation.target).hasClass('aside-base')) {
        pendingWidget.attr('ic-deps', widgetPath);
      }
    });
  });
  /* eslint-enable no-undef */

  observer.observe(sidebar, { childList: true });
  observer.observe(sidebarContent, { childList: true });
});

onmount('.pending-task-aside-render-async', function () {
  const $pendingAsideRenderAsync = $(this);
  let $pendingTaskAsideLoader;
  let $pendingTaskAsideContent;

  $pendingAsideRenderAsync
    .on('beforeSend.ic', function (...params) {
      const settings = params[3];

      $pendingTaskAsideLoader = $('#pending-task-aside-loader');
      $pendingTaskAsideContent = $('#pending-task-aside-content');

      if (settings.url.includes('/pendings')) {
        $pendingTaskAsideLoader.removeClass('d-none');
        $pendingTaskAsideContent.addClass('d-none');
      }
    })
    .on('complete.ic', function (...params) {
      const data = params[2];
      const status = params[3];

      setTimeout(() => {
        if (data.includes('pending-task-aside-render-async') && status === 'success') {
          $pendingTaskAsideLoader.addClass('d-none');
          $pendingTaskAsideContent.removeClass('d-none');
        }
      }, 100);
    });
});

onmount('.edit_user_config', function () {
  const $updatePendingTaskConfigForm = $(this);

  /* eslint-disable no-undef */
  $updatePendingTaskConfigForm
    .on('success.ic', function () {
      isMobile()
        ? Intercooler.triggerRequest($('#pending-task-aside-mobile-render-async'))
        : Intercooler.triggerRequest($('#pending-task-widget'));
    });
  /* eslint-enable no-undef */
});

isMobile()
  ? onmount('#tareas-tab', () => openPendingTaskSidebar('mobile'))
  : onmount('#toggle-aside-reminder-navbar-loaded', () => openPendingTaskSidebar('desktop'));

window.addEventListener('resize', () => {
  isMobile()
    ? openPendingTaskSidebar('mobile')
    : openPendingTaskSidebar('desktop');
});
